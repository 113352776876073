import {
  PageTemplate,
  HeaderTabs,
  Invoices as InvoicesComponent,
  Contracts,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Invoices() {
  const [currentTab, setCurrentTab] = useState("invoices");

  const { t } = useTranslation();

  const tabs = {
    invoices: {
      label: t("invoicesTabLabel"),
      content: <InvoicesComponent />,
    },
    contracts: {
      label: t("contractsTabLabel"),
      content: <Contracts />,
    },
  };

  useEffect(() => {}, [currentTab]);

  return (
    <PageTemplate
      SectionHeaderContent={
        <HeaderTabs
          items={tabs}
          handleSetCurrentTab={(tab) => setCurrentTab(tab)}
          currentTab={currentTab}
        />
      }
      title={t("invoicesLabel")}
      noSectionTopPadding={true}
    >
      {tabs[currentTab].content}
    </PageTemplate>
  );
}
